import FingerprintJS from "@fingerprintjs/fingerprintjs";
import CryptoJS from 'crypto-js'; // 用于哈希
import { ethers } from "ethers";


async function getDeviceFingerprint(nonce='') {
    // console.log('nonce:', nonce);
    // if(localStorage.getItem('finalFingerprintBytes')){
    //     return localStorage.getItem('finalFingerprintBytes');
    // }
    // 加载 FingerprintJS
    const fp = await FingerprintJS.load();
    // 获取默认设备指纹
    const result = await fp.get();
    const defaultFingerprint = result.visitorId; // FingerprintJS 提供的指纹
    // console.log('默认设备指纹:', defaultFingerprint);

    // 获取自定义参数
    const customParams = {
        mySignId: generateFingerprint(),
        version: '1.0.0',
    };
    if(nonce){
        customParams.nonce = nonce;
    }
    // console.log('自定义参数:', customParams);

    // 合并默认指纹和自定义参数
    const combinedData = `${defaultFingerprint}-${Object.values(customParams).join('-')}`;
    // console.log('合并后的数据:', combinedData);
    // 对合并后的数据进行哈希，生成最终唯一指纹
    const finalFingerprint = CryptoJS.SHA256(combinedData).toString(CryptoJS.enc.Hex);
    const finalFingerprintBytes = getBytes32FromVisitorId(finalFingerprint);
    // console.log('最终唯一指纹:', finalFingerprintBytes);
    localStorage.setItem('finalFingerprintBytes', finalFingerprintBytes);
    return finalFingerprintBytes;
}

function hashString(str) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        const char = str.charCodeAt(i);
        hash = ((hash << 5) - hash) + char;
        hash |= 0; // Convert to 32bit integer
    }
    return hash.toString(16);
}

function getScreenSize() {
    return `${screen.width}x${screen.height}`;
}

function getViewportSize() {
    return `${window.innerWidth}x${window.innerHeight}`;
}

function getFonts() {
    const fontList = [
        'Arial', 'Verdana', 'Times New Roman', 'Courier New', 'Georgia', 'Comic Sans MS', 'Trebuchet MS', 'Arial Black', 'Impact'
    ];
    const availableFonts = [];

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    const text = 'abcdefghijklmnopqrstuvwxyz0123456789';

    fontList.forEach((font) => {
        context.font = `16px ${font}`;
        const width = context.measureText(text).width;
        context.font = `16px monospace`;
        if (context.measureText(text).width !== width) {
            availableFonts.push(font);
        }
    });

    return availableFonts.join(',');
}

function getWebGLRenderer() {
    const canvas = document.createElement('canvas');
    const gl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');
    if (gl) {
        const debugInfo = gl.getExtension('WEBGL_debug_renderer_info');
        if (debugInfo) {
            return gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL);
        }
    }
    return null;
}

function getCanvasFingerprint() {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.textBaseline = 'top';
    context.font = '14px Arial';
    context.textBaseline = 'alphabetic';
    context.fillStyle = '#f60';
    context.fillRect(125, 1, 62, 20);
    context.fillStyle = '#069';
    context.fillText('Hello, world!', 2, 15);
    context.fillStyle = 'rgba(102, 204, 0, 0.7)';
    context.fillText('Hello, world!', 4, 17);
    return canvas.toDataURL();
}

function generateFingerprint() {
    const screenSize = getScreenSize();
    const viewportSize = getViewportSize();
    const colorDepth = screen.colorDepth;
    const userAgent = navigator.userAgent;
    const platform = navigator.platform;
    const language = navigator.language;
    const plugins = Array.from(navigator.plugins).map(plugin => plugin.name).join(',');
    const doNotTrack = navigator.doNotTrack;
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const timeZoneOffset = new Date().getTimezoneOffset();
    const fonts = getFonts();
    const hardwareConcurrency = navigator.hardwareConcurrency;
    const deviceMemory = navigator.deviceMemory;
    const webGLRenderer = getWebGLRenderer();
    const canvasFingerprint = getCanvasFingerprint();
    const touchSupport = 'ontouchstart' in window || navigator.maxTouchPoints > 0;

    const fingerprint = [
        screenSize, viewportSize, colorDepth, userAgent, platform, language,
        plugins, doNotTrack, timeZone, timeZoneOffset, fonts, hardwareConcurrency,
        deviceMemory, webGLRenderer, canvasFingerprint, touchSupport
    ].join('|');

    return hashString(fingerprint);
}

function getBytes32FromVisitorId(visitorId) {
    // 将 visitorId 转换为 UTF-8 字节数组
    const visitorIdBytes = ethers.utils.toUtf8Bytes(visitorId);
    // 计算 visitorId 的哈希值
    const hash = ethers.utils.keccak256(visitorIdBytes);
    // 直接返回哈希值，不需要再次 hexlify
    return hash; // hash 本身已经是一个 32 字节的 hex 字符串
}

const signBytes = await getDeviceFingerprint;
export { signBytes };